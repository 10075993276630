
import './button.scss'

interface buttonOptions {
    img: {};
}
function Button({imgSrc, onUse, alt="", options}:{imgSrc:string, onUse:()=>void, alt:string, options?:buttonOptions}) {
    return (
        <button className="button" onClick={onUse}>
            <img alt={alt} src={imgSrc} style={options?.img}></img>
        </button>
    )
}
export default Button