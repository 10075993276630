import React from "react"
import Navbar from "../components/navbar/navbar"
import { FileBrowser } from "../components/filesystem/audioBooks"
function HomeView() {
    return (
        <React.Fragment>
            <Navbar title="Home"></Navbar>
            <FileBrowser/>
        </React.Fragment>
    )
}

export default HomeView