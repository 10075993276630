import React, { useEffect, useState } from "react"
import Navbar from "../components/navbar/navbar"
import Player from "../components/player/player"
import {indexedDBServiceDirecories} from "../ts/indexedDB"

function PlayView() {
    const [title, setTitle] = useState<string>('')
    const [handle, setHandle] = useState<FileSystemDirectoryHandle>()
    const init = async ()=>{
        const _title = window.location.pathname.split('/')[2].replace('%20', ' ');
        const _handle = await indexedDBServiceDirecories.getStoredFolderHandle('handles', _title)
        setHandle(_handle)
        setTitle(_title);
    }
    useEffect(()=>{
        init()
    }, [])
    return (
        <React.Fragment>
            <Navbar title={title}></Navbar>
            {(handle !== undefined)?(<Player handle={handle} title={title}></Player>):''}
        </React.Fragment>
    )
}

export default PlayView