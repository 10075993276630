import Button from "../../buttons/button"
import playIcon from "../../../icons/play.png"
import pauseIcon from "../../../icons/pause.png"
import forward10 from "../../../icons/forward-10.png"
import replay10 from "../../../icons/replay-10.png"
import next from "../../../icons/fast-forward.png"
import previous from "../../../icons/rewind.png"
import React, {useEffect, useState} from "react"
import { AudioData } from "../../../ts/AudioMetaData"
import './control.scss'
import Beam from "./beam/beam"

function Control({audioData}:{audioData:AudioData}) {
    const [playing, setPlaying] = useState(false)
    const [fullTime, setFullTime] = useState(0)
    const [time, setTime] = useState(0);
    useEffect(()=>{
        setFullTime(audioData.getCurrentDuration())
        audioData.registerTimer(setTime)
    }, [audioData, time])
    const togglePlaying = ()=> {
        if(playing) {
            audioData.pause()
        }else {
            audioData.playAudio();
        }
        setPlaying(!playing)
    }
    const updateTime = (time: number)=>{
        audioData.setTime(time)
        setTime(time)
    }
    const onUse = (type:string)=>{
        switch(type){
            case 'play/pause':
                togglePlaying();
                break;
            case 'forward10':
                audioData.setTime(time + 10)
                break;
            case 'replay10':
                audioData.setTime(time - 10)
                break;
            case 'next':
                audioData.skipTrack()
                break;
            case 'previous':
                audioData.skipTrack(false)
                break;
        }
        
    }
    return (
        <div className="console">
            <div>
                <Button alt="" imgSrc={previous} onUse={()=>onUse('previous')}/>
                <Button alt="" imgSrc={replay10} onUse={()=>onUse('replay10')}/>
                <Button alt="" imgSrc={(!playing)?playIcon:pauseIcon} onUse={()=>onUse('play/pause')}/>
                <Button alt="" imgSrc={forward10} onUse={()=>onUse('forward10')}/>
                <Button alt="" imgSrc={next} onUse={()=>onUse('next')}/>
            </div>
            <Beam dur={fullTime} curTime={time} updateTime={updateTime}></Beam>
        </div>
    )
}

export default Control