import { DirectoryInterface } from "react"
import './directory.scss'
const Directory = ({dir}:{dir:DirectoryInterface})=>{
    return (
        <div className="directory">{(dir.imgURL !== '')?
            <a href={'/play/' + dir.name}><img alt="" height="150" width="150" src={dir.imgURL}></img></a> :
            <a href={'/play/' + dir.name}><div className="no-image">{dir.name}</div></a>} </div>
    )
}

export default Directory