import { parseBlob, IAudioMetadata } from "music-metadata";
import { indexedDBServiceAudio } from "./indexedDB";
import { Audioplayer } from "./Audioplayer";


import {
    supported
} from 'browser-fs-access';



interface Track {blob: any, metadata: IAudioMetadata}
class AudioData {
    //in seconds
    private id: string;
    private handle: FileSystemDirectoryHandle | File[];
    private tracks: Track[] = [];
    private currentDuration: number = 0;
    private duration: number = 0;
    private alreadyPlayed: number = 0; //from currentTrack
    private currentTrack: number = 1;
    public imageURL:string = '';
    private currentTime: number = 0;
    private updateMetadata: ()=>void

    private registeredTimer: ((time: number) => void) | undefined;

    public audioPlayer: Audioplayer | null; 

    constructor(handle: FileSystemDirectoryHandle, title: string, callback:()=>void) {
        this.id = title;
        this.handle = handle;
        this.audioPlayer = null; 
        this.init(callback);
        this.updateMetadata = callback
    }

    /* Callbackfunction which gets passed to the audioplayer and gets called once the track in the audioplayer ends */
    nextTrack= () => {
        this.currentTrack += 1;
        if(this.tracks[this.currentTrack] !== undefined) {
            // this.audioPlayer!.createSource(this.tracks[this.currentTrack].blob)
            this.audioPlayer!.playAudio(this.tracks[this.currentTrack - 1].blob)
        }
        this.updateMetadata()
    }

    buildImgURL = async (file: Blob):Promise<string> => {
      return new Promise(async (res)=>{
          let _res = '';
          const parsed = await parseBlob(file);
          const picture = parsed.common.picture;
          if(picture !== undefined) {
              _res = URL.createObjectURL(new Blob([picture![0].data.buffer], {type: picture![0].format}))
          }
          res(_res)
      })
    }

    getMetadata = ()=>{
        return {
            tracks: this.tracks.length,
            currentTrack: this.currentTrack,
            imgUrl: this.imageURL
        }
    }
    async init(callback:()=>void) {
        const t = await indexedDBServiceAudio.getStoredFolderHandle('albums', this.id);
        if(t !== undefined) {
            this.currentTrack = t.track;
            this.currentTime = t.time
        }
        //Filesystem- access supported?
        if(supported) {
            for await (const track of this.handle.values()) {
                const _file = await track.getFile()
                const metadata = await parseBlob(_file)
                this.tracks?.push({metadata: metadata, blob: _file})
                
                this.duration += metadata.format.duration as number
            }
            this.tracks.sort((a, b) => {
                return a.metadata.common.track.no! - b.metadata.common.track.no!
            } )
            this.imageURL = URL.createObjectURL( new Blob([this.tracks[0].metadata.common.picture![0].data.buffer], {type: this.tracks[0].metadata.common.picture![0].format} ))

        } else {
            for (let i = 0; i < (this.handle as File[]).length; i++) {
                const metadata = await parseBlob((this.handle as File[])[i])
                this.tracks.push({metadata: metadata, blob: (this.handle as File[])[i]});
                
            }
            this.imageURL = await this.buildImgURL((this.handle as File[])[0])
            this.tracks.sort((a, b) => {
                return a.metadata.common.track.no! - b.metadata.common.track.no!
            })
        }
        this.currentDuration = this.tracks[this.currentTrack - 1].metadata.format.duration!
        this.audioPlayer = new Audioplayer(this.nextTrack, this.updateCurrentTime)
        this.updateCurrentTime(this.currentTime)
        callback()
    }

    skipTrack = (forward = true) => {
        this.currentTrack = forward ? this.currentTrack + 1 : this.currentTrack - 1;
        if(this.currentTrack < 1) {
            this.currentTrack = 1;
        } else if (this.currentTrack > this.tracks.length - 1) {
            this.currentTrack = this.tracks.length - 1;
        }
        if(this.tracks[this.currentTrack] !== undefined && this.audioPlayer?.playing) {
            this.audioPlayer.pauseAudio();
            this.audioPlayer!.playAudio(this.tracks[this.currentTrack - 1].blob)
        }
        this.setTime(0)
        this.savepoint()
        this.updateMetadata()
    }


    updateCurrentTime = (time: number) => {
        this.currentTime = time
        if(this.registeredTimer !== undefined) {
            this.registeredTimer(time)
        }
    }

    getPlayed():number {
        return Math.round(this.alreadyPlayed/this.duration)
    }

    getDuration():number {
        return Math.round(this.duration);
    }
    getCurrentDuration():number {
        return Math.round(this.currentDuration)
    }
    getImgURL():string {
        return this.imageURL;
    }

    setTime(time: number) {
        if(time < 0) {
            time = 0;
        } else if(time > this.currentDuration) {
            time = this.currentDuration
        }
        this.audioPlayer!.setTime(time)
        this.updateCurrentTime(time)
        this.savepoint()
    }

    playAudio() {
        this.audioPlayer!.playAudio(this.tracks[0].blob)
    }
    registerTimer(callback:(time:number)=>void) {
        this.registeredTimer = callback;
    }
    pause() {
        this.audioPlayer!.pauseAudio()
        this.savepoint()
    }

    savepoint() {
        indexedDBServiceAudio.storeFolder('albums', this.id, {
            id: this.id,
            track: this.currentTrack,
            time: this.currentTime
        })
    }

}
export {AudioData}