import './player.scss'
import Control from './control/control'
import { useEffect, useState } from 'react'
import { AudioData } from '../../ts/AudioMetaData'

interface metadataInterface {
    imgUrl: string,
    tracks: number,
    currentTrack: number,
}
const Player = ({handle, title}:{handle:FileSystemDirectoryHandle, title: string})=>{
    const [metadata, setMetadata] = useState<metadataInterface>({
        imgUrl: '',
        tracks: 0,
        currentTrack: 0
    })
    const [audioData, setAudioData] = useState<AudioData | null>(null)

    useEffect(()=>{
        const audioDataInstance = new AudioData(handle, title, ()=>{
            setMetadata(audioDataInstance!.getMetadata())
        });
        setAudioData(audioDataInstance)
        // getMetadata()
    }, [handle, title])
    return (
        <div className="player">
            <div className='screen'>
                <div><img alt={"The cover of the audiobook " + handle.name} src={audioData?.imageURL}></img></div>
                <div className='track-counter'>{metadata.currentTrack} / {metadata.tracks}</div>
            </div>
            
            {audioData!==null?<Control audioData={audioData}/>:''}
        </div>
    )
}

export default Player