import './navbar.scss'
function navbar({title}:{title: string}){
    return (
        <div className="navbar">
            <div onClick={()=>window.open()}>logo</div>
            <div>{title}</div>
            <div>s</div>
        </div>
    )
}

export default navbar