

const ApologiesView = ()=>{
    return (
    <div style={{textAlign:"left", padding: '2rem'}}>
        <h1> This application uses technology which is not available for your Device currently :( </h1>
        <p> To create a proper usability the application needs to get access to a directory of your Device which holds the audiobooks!</p>
        <p> Right now theres no way for mobile devices to grant access to directories in the browser. For that reason I decided to disallow this web-app for mobile devices!</p>
        <p> This application was build for educational reasons (I wanted to get more into react). And maybe I want to learn more about native applications. At least I already have a project Idea. Once it is build it will be downloadable over this website :D</p>
    </div>)
}

export default ApologiesView