class IndexedDBService {
    private dbName: string;
    private dbVersion: number;
    private db: IDBDatabase | null;

    constructor(dbName: string, storename: string) {
        this.dbName = dbName;
        this.dbVersion = 3;
        this.db = null;
        this.init(storename)
    }
    async init(storename:string): Promise<IDBDatabase> {
        return new Promise((res, rej) => {
            const request = indexedDB.open(this.dbName, 3);
            request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
                const db = (event.target as IDBOpenDBRequest).result;
                if (!db.objectStoreNames.contains(storename)) {
                  db.createObjectStore(storename, { keyPath: 'id' });
                }
              };
            request.onsuccess = (event: Event) => {
              this.db = (event.target as IDBOpenDBRequest).result
              res((event.target as IDBOpenDBRequest).result);
            };
            request.onerror = (event: Event) => {
              rej((event.target as IDBOpenDBRequest).error);
            };
        })
    }
    async exists(storeName: string, id: string): Promise<boolean> {
        if(this.db === null) return false;
        const transaction = this.db!.transaction(storeName, 'readonly')
        const store = transaction.objectStore(storeName)
        return new Promise((resolve, reject) => {
          const request = store.get(id);
          request.onsuccess = (event: Event) => {
            resolve((event.target as IDBRequest).result !== undefined);
          };
          request.onerror = (event: Event) => {
            reject((event.target as IDBRequest).error);
          };
        });
      }
    async storeFolder(storename:string, id: string, obj:any): Promise<void> {
        if(this.db === null) return
        if(await this.exists('handles', id)) return;
        const transaction = this.db!.transaction(storename, 'readwrite');
        const store = transaction.objectStore(storename);
        await store.put({ id: id, handle: obj });
    }
    async getStoredFolderHandle(storename:string, id: string): Promise<any> {
        if(this.db === null) return
        const transaction = (this.db as IDBDatabase).transaction(storename, 'readonly');
        const store = transaction.objectStore(storename);
        return new Promise((resolve, reject) => {
          const request = store.get(id);
          request.onsuccess = (event: Event) => {
            resolve((event.target as IDBRequest).result?.handle);
          };
          request.onerror = (event: Event) => {
            reject((event.target as IDBRequest).error);
          };
        });
      }
}
const indexedDBServiceDirecories = new IndexedDBService('fileHandlesDB', 'handles');
const indexedDBServiceAudio = new IndexedDBService('audio', 'albums')

export {indexedDBServiceDirecories, indexedDBServiceAudio}