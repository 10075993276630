import { useEffect, useRef, useState } from 'react'
import './beam.scss'

const Beam = ({dur, curTime, updateTime}: {dur:number, curTime:number, updateTime: (time:number)=>void})=>{
    const [_curTime, setCurTime] = useState(0)
    const [pos, setPos] = useState<number>(0)
    const beam = useRef<HTMLDivElement>(null);
    const [mousedown, setMouseDown] = useState(false)
    useEffect(()=>{
        if(!mousedown) {
            setPos(((curTime/dur)*100))
            setCurTime(curTime)
        }
    }, [mousedown, curTime, dur])
    const quitChange = ()=>{
        setMouseDown(false);
        updateTime(_curTime)
    }
    const mouseDown= (e:React.MouseEvent<HTMLDivElement>)=>{
        setMouseDown(true)
        changePosition(e)
    }
    const changePosition = (e:React.MouseEvent<HTMLDivElement>)=>{
        const __curTime = Math.round(e.nativeEvent.offsetX/beam.current!.offsetWidth * dur)
        if(__curTime>=0 && __curTime<= dur) {
            setCurTime(Math.round(e.nativeEvent.offsetX/beam.current!.offsetWidth * dur))
            setPos((e.nativeEvent.offsetX/beam.current!.offsetWidth * 100))
        }

    } 
    const beauifyTime = (time:number):string=>{
        let res = '';
        const _min = Math.floor(time/60);
        const _secs = time - _min * 60;
        
        if(_min < 10) {
            res += '0' + _min + ':';
        } else {
            res += _min + ':';
        }
        if(_secs < 10) {
            res += '0' + _secs;
        } else {
            res += _secs;
        }
        return res;
    }
    return(
        <div className='timer'>
        <div className="current-time-number">{beauifyTime(_curTime)}</div>
        <div className="beam" onMouseDown={mouseDown} onMouseMove={mousedown?changePosition:undefined} onMouseUp={quitChange} ref={beam}>
            <div className="currentTime" style={{width: + pos + '%'}}>
            </div>
        </div>
        <div className="time-number">{beauifyTime(dur)}</div>
    </div>
    )
}

export default Beam