import React, { useEffect, useState, useCallback, DirectoryInterface } from 'react';
import {indexedDBServiceDirecories} from '../../ts/indexedDB';
import Directory from '../directory/directory';
import './audiobooks.scss'
import Button from '../buttons/button';
import directoryIcon from '../../icons/directory.png'
import {
    supported,
    directoryOpen,
    FileWithDirectoryAndFileHandle,
  } from 'browser-fs-access';
  import {parseBlob} from 'music-metadata'
declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
      // extends React's HTMLAttributes
      directory?: string;        // remember to make these attributes optional....
      webkitdirectory?: string;
    }
    interface DirectoryInterface {
        name: string,
        imgURL: string,
    }
  }


const FileBrowser = () => {
  const [directories, setDirectories] = useState<DirectoryInterface[]>([])
  const openDirectory = ():Promise<FileList | null> => {
    return new Promise((res)=> {
        const input = document.createElement("input");
        input.type = 'file'
        input.setAttribute('webkitdirectory', '');
        input.setAttribute('mozdirectory', '');
        input.setAttribute('msdirectory', '');
        input.setAttribute('odirectory', '');
        input.setAttribute('directory', '');
        input.multiple = true;
        input.addEventListener('change', ()=>{
            res(input.files)
        })
        input.click()
    })
  }

  const createDirectory = useCallback(async () => {
    // const dirHandle:FileSystemDirectoryHandle = await window.showDirectoryPicker();
    if (supported) {
        const folder: FileSystemDirectoryHandle[] | FileWithDirectoryAndFileHandle[] = await directoryOpen()
        indexedDBServiceDirecories.storeFolder('handles', 'selectedFolder', (folder[0] as FileWithDirectoryAndFileHandle).directoryHandle!)
    } else {
        // let _files: FileSystemDirectoryHandle[] | FileWithDirectoryAndFileHandle[] = await directoryOpen({recursive:true});
        let _files = await openDirectory()
        if (_files === null) return
        let books: {[key: string]: FileWithDirectoryAndFileHandle[]} = {};
        let subdirectories: string[] = [];
        for (let i = 0; i < _files.length; i++) {
            const file = _files[i];
            if (file.name !== '.DS_Store') {
                const dir = file.webkitRelativePath.split('/')[1]
                if(books[dir] !== undefined) {
                    books[dir].push(file);
                } else {
                    books[dir] = [file];
                }
            }
        }
        for (const book in books) {
            subdirectories.push(book);
            indexedDBServiceDirecories.storeFolder('handles', book, books[book])
        };
        indexedDBServiceDirecories.storeFolder('handles', 'selectedFolder', subdirectories)
    }
  }, []);

  const buildImgURL = async (file: Blob):Promise<string> => {
    return new Promise(async (res)=>{
        let _res = '';
        const parsed = await parseBlob(file);
        const picture = parsed.common.picture;
        if(picture !== undefined) {
            _res = URL.createObjectURL(new Blob([picture![0].data.buffer], {type: picture![0].format}))
        }
        res(_res)
    })
  }
  const getDirectory = useCallback(async () => {
    // file access API supported
    if(supported) {
        const handler = await indexedDBServiceDirecories.getStoredFolderHandle('handles', 'selectedFolder')
        const _directories: DirectoryInterface[]= [];
        if(handler !== undefined) {
            let _files = []
            for await (const entry of handler.values()) {
                if(entry.name !== '.DS_Store') {
                    // const _file = await entry.getFile()
                    const _directory: DirectoryInterface = {
                        name: entry.name,
                        imgURL: '',
                        }
                    for await (const track of entry.values()) {
                        let _file = await track.getFile()
                        let res = await parseBlob(_file)
                        const picture = res.common.picture
                        if(res.common.picture !== undefined) {
                           _directory.imgURL = URL.createObjectURL(new Blob([picture![0].data.buffer], {type: picture![0].format}))
                        }
                        break;
                    }
                    _directories.push((_directory))
                    _files.push(entry)
                    indexedDBServiceDirecories.storeFolder('handles', entry.name, entry)
                }
            }
            setDirectories(_directories)
        }
    } else {
        const handler = await indexedDBServiceDirecories.getStoredFolderHandle('handles', 'selectedFolder') as string[]
        if(handler === undefined) return
        const _directories: DirectoryInterface[] = [] 
        const len = handler.length;
        for (let i = 0; i < len; i++) {
            const subdir = handler[i];
            const _subdirectory = await indexedDBServiceDirecories.getStoredFolderHandle('handles', subdir) as any;
            _directories.push({
                name: subdir,
                imgURL: await buildImgURL(_subdirectory[0])
            })
            
        }
        setDirectories(_directories)
    }
  }, []);

  useEffect(() => {
    getDirectory()
  }, [getDirectory])

  return (
    <div className='file-browser'>
        {directories.length===0?
        <div className='directorySetting'><Button options={{img: {width:'10rem'}}} imgSrc={directoryIcon} alt='Button to setup the directory where the Audiobooks are stored!' onUse={()=>createDirectory()}></Button></div>:''}
        {directories.map((file, index)=> (<Directory dir={file} key={index}></Directory>))}
    </div>
  )
}

export {FileBrowser}