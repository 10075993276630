
class Audioplayer{
    public playing = false;
    private ctx = new window.AudioContext()
    private source?: AudioBufferSourceNode;
    private track?: Blob;
    private time: number;
    private timeSetted = false;
    private startTimestmp = 0;
    private nextTrack: ()=>void
    private updateCurrentTime: (currentTime:number)=>void

    private isSettingTime = false;
    private skipTimeout: NodeJS.Timeout | null = null;
    private timerInterval: NodeJS.Timer | null = null;
    constructor(nextTrack:()=>void, updateCurrentTime: (currentTime:number)=>void) {
        this.time = 0;
        this.nextTrack = nextTrack
        this.updateCurrentTime = updateCurrentTime
    }
    
    public createSource(track:Blob, at: number = 0) {
        this.track = track;
        return new Promise(async (res)=>{
            const arrayBuffer = await track.arrayBuffer()
            const decodedBuffer = await this.ctx.decodeAudioData(arrayBuffer);
            this.source = this.ctx.createBufferSource();
            this.source.buffer = decodedBuffer;
            this.source.connect(this.ctx.destination);
            this.source.onended = ()=>{
                this.time = 0;
                this.nextTrack()
            }

            res(undefined)
        })

    }
    async playAudio(track:any) {
        this.startTimestmp = this.ctx.currentTime
        if(this.timerInterval) {
            clearInterval(this.timerInterval)
        }

        await this.createSource(track)
        this.source!.start(0, this.time)

        this.timerInterval = setInterval(this.timer, 1000)
        this.playing = true;
    }
    async pauseAudio() {
        this.source!.stop();
        this.source!.onended = null;
        this.source!.disconnect()
        this.source = undefined;
        this.time = Math.floor(this.time + this.ctx.currentTime - this.startTimestmp) - 1;
        this.time = this.time > 0 ? this.time : 0;
        this.playing = true;
        clearInterval(this.timerInterval!)
    }
    async setTime(time:number) {
        this.time = time;
        if(this.isSettingTime) {
            return;
        }
        this.isSettingTime = true;
        if (this.ctx.state === 'running' && this.source) {
            this.isSettingTime = true;
            this.source.stop();
            this.source.onended = null;
            this.source.disconnect()
            await this.createSource(this.track!)
            this.source!.start(0, this.time);
        }
        this.timeSetted = true;
        this.startTimestmp = this.ctx.currentTime
        this.isSettingTime = false;
    }

    timer = () => {
        this.updateCurrentTime(Math.round(this.time + this.ctx.currentTime - this.startTimestmp))
    }
}

export {Audioplayer}