// import React from 'react';
// import logo from './logo.svg';
import { Route, Routes} from 'react-router-dom';
import HomeView from './views/home';
import PlayView from './views/play';
import Apologies from './views/Apologies'
import './App.css';
import { useEffect } from 'react';

declare global {
  interface Window {
      showOpenFilePicker: any;
      showDirectoryPicker: any;
      jsmediatags: any
  }
  interface FileSystemDirectoryHandle {
      values: any
  }
  interface BaseSyntheticEvent {
    clientX: number
}
}


function App() {
  useEffect(()=>{
    checkDevice()
  })
  const checkDevice = ()=>{
    const userAgent = navigator.userAgent;
    if (/mobile/i.test(userAgent)) {
        redirect()
    } else if (/tablet/i.test(userAgent)) {
        redirect()
    } else if (/iPad|Android|Touch/i.test(userAgent)) {
        redirect()
    } else {
      if(window.location.pathname === '/') {
        window.location.href = window.location.origin + '/home'
      }
    }
  }

  const redirect = ()=>{
    if (window.location.pathname !== '/apologies') {
      window.location.href = window.location.origin + '/apologies'
    }
  }
  return (
    <div className="App">
      <Routes>
        <Route path="/play/:id" element={<PlayView/>}/>
        <Route path="/home" element={<HomeView/>}/> 
        <Route path="/settings"/>
        <Route path="/upload" />
        <Route path="/apologies" element={<Apologies/>}/>
      </Routes>
    </div>
  );

}

export default App;
